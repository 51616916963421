import React from 'react'
import { Trans } from 'react-i18next'
import { notification } from '../components/share/ANTD/Notification'

export const openNotificationWithIcon = (type, message, description) => {
  if (message && !description) {
    return notification[type]({
      message: (
        <span className="message_title">
          <Trans i18nKey={message} />
        </span>
      )
    })
  }

  if (!message && description) {
    return notification[type]({
      description: (
        <span className="message_description">
          <Trans i18nKey={description} />
        </span>
      )
    })
  }

  if (!message && !description) return null

  return notification[type]({
    message: (
      <span className="message_title">
        <Trans i18nKey={message} />
      </span>
    ),
    description: (
      <span className="message_description">
        <Trans i18nKey={description} />
      </span>
    )
  })
}

import css from 'styled-jsx/css'
export const RegisterStyle = css`
  .register-page {
    min-height: 100vh;
    position: relative;
    background-color: #f6f8fc !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 40px 0 10px 0;
  }
  .register-form-wrapper {
    width: 100%;
    height: 100%;
  }
  .logo {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 24px;
  }
  .header-logo-img {
    height: 52px;
  }

  .register-form {
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
    border-radius: 20px;
    background-color: #fff;
  }
  .form-header {
    border-bottom: 1px solid #e8e8e8;
  }
  .form-body {
    padding: 24px 24px 24px 24px;
  }
  .form-header-referral {
    font-size: 20px;
    color: #262626;
    line-height: 28px;
    font-weight: bold;
    margin: 0 auto 16px auto;
    text-align: center;
    max-width: 70%;
  }

  .btn-active,
  .btn-inActive {
    border: none;
    box-shadow: none;
    width: 100%;
    font-size: 20px;
    line-height: 28px;
    color: #4f535b;
    border-radius: 10px;
    min-height: 40px;
    cursor: pointer;
  }
  .btn-active:focus,
  .btn-inActive:focus {
    outline: unset;
  }
  .btn-inActive {
    background-color: #fff;
  }
  .btn-inActive:hover {
    color: #1890ff;
  }
  .btn-active {
    color: #1890ff;
    background-color: #fff;
    font-weight: 500;
  }

  .link-forgot-pw {
    font-size: 14px;
    line-height: 20px;
    color: #1890ff;
    text-decoration: none;
    font-weight: 500;
    margin-bottom: 24px;
    display: block;
  }

  .divider {
    border-top-color: #bfbfbf;
  }

  .btn-google,
  .btn-facebook {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    color: #fff;
  }
  .icon {
    color: #fff;
    margin-top: 2px;
  }

  .icon-facebook {
    margin-right: 8px;
    display: flex;
  }

  .btn-google {
    background-color: #ea4335;
    border-color: #ea4335;
  }

  .btn-facebook {
    background-color: #4267b2;
    border-color: #4267b2;
  }

  .text-bottom {
    color: #262626;
    text-align: center;
    margin-bottom: 0;
  }
  .link-bottom {
    font-weight: 600;
    margin-left: 8px;
    color: #1890ff;
  }

  .profile-drop-down-link {
    display: flex;
    align-items: center;
    color: #262626;
    font-weight: bold;
  }
  img.lang-flag {
    height: 20px;
    margin-right: 5px;
  }

  .text-high-light {
    color: #262626;
    text-decoration: underline;
  }

  .error-text {
    color: #ea4335;
    margin-bottom: 20px;
  }

  .btn-submit {
    width: 100%;
    border-radius: 5px;
  }
  .block-right {
    margin-top: 170px;
  }

  .block-right_title {
    font-size: 30px;
    line-height: 38px;
    color: #262626;
    margin-bottom: 24px;
  }
  .block-right .detail {
    font-size: 20px;
    line-height: 28px;
    color: #8c8c8c;
    margin-bottom: 16px;
  }
  .block-right .detail-text-small {
    color: #8c8c8c;
  }
  .block-right .icon {
    color: #308b0b;
  }
  .block-right .img-apps {
    display: flex;
    flex-direction: column;
    margin-right: 30px;
  }
  .block-right .size-app {
    height: 40px;
  }
  .qr-code-view {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .qr-code-view .img-QR-code {
    width: 120px;
    height: 120px;
  }
  .qr-code-view .title {
    text-align: center;
    padding: 8px;
    color: #262626;
    font-size: 14px;
    font-weight: 400;
    background-color: #fff;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  .high-light {
    color: #262626;
  }
  .img-parner {
    filter: grayscale(100%);
  }
  .title {
    font-size: 20px;
    line-height: 38px;
    margin-bottom: 8px;
    text-align: center;
    font-weight: 600;
  }
  .login-message {
    margin-bottom: 20px;
    text-align: center;
    justify-content: center;
  }
  @media screen and (max-width: 576px) {
    .form-header-referral {
      margin-bottom: 24px;
    }
    .register-page {
      padding-bottom: 10px;
    }
    .text {
      margin-left: 5px;
    }
    .register-page {
      background-color: #fff !important;
    }
    .register-form {
      padding: 0;
      border-radius: none;
      box-shadow: none;
      background-color: transparent;
    }
    .btn-active,
    .btn-inActive {
      font-size: 18px;
    }
  }
`

export const RegisterReferralGlobalStyle = css.global`
  body .ant-divider-plain.ant-divider-with-text {
    margin: 24px 0;
  }
  body .btn-submit,
  body .ant-btn.btn-google {
    height: 48px;
  }
  @media screen and (max-width: 576px) {
    body .mb-mobile-40 {
      margin-bottom: 40px;
    }
  }
`
export const RegisterGlobalStyle = css.global`
  .ant-layout .ant-btn.btn-google .icon,
  .ant-layout .ant-btn.btn-google .icon {
    color: #fff;
    margin-top: 2px;
  }
  .register-page .tab-header.active {
    border-bottom: 2px solid #1890ff;
  }
  .register-page .tab-header {
    border-bottom: 2px solid #e8e8e8;
  }
  .register-page .divider-login .ant-divider-inner-text {
    /* color: #595959; */
  }
  .wrapper-container {
    max-width: 1440px;
    padding-left: 15px;
    padding-right: 15px;
    margin-left: auto;
    margin-right: auto;
    height: 100%;
  }
  .ant-form-item-label {
    padding-bottom: 2px !important;
  }
  .ant-form-item {
    margin-bottom: 16px;
  }

  .md\:pb-4 {
    padding-bottom: 16px;
  }
  .md\:pt-4 {
    padding-top: 16px;
  }

  .md\:flex-row {
    flex-direction: row;
  }
  .md\:mt-0 {
    margin-top: 0;
  }
  @media screen and (max-width: 768px) {
    .wrapper-container {
      padding-left: 0;
      padding-right: 0;
    }
    .xs\:pb-0 {
      padding-bottom: 0;
    }
    .xs\:pt-0 {
      padding-top: 0;
    }
    .xs\:flex-column {
      flex-direction: column;
    }
    .xs\:mt-4 {
      margin-top: 16px;
    }
  }
`
